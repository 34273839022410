<template>
  <el-dialog
  title="提示"
  :visible.sync="centerDialogVisible"
  width="30%"
  >
  <span>此机构已拥有方案，若需要对词库进行调整请联系相关销售</span>
  <span slot="footer" 
  class="dialog-footer  "
  style="display:flex;justify-content: center;">
    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
export default {
data() {
  return {
    centerDialogVisible:false
  }
},
}
</script>

<style>

</style>