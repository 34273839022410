<template>
  <div>
    <el-dialog :close-on-click-modal="false" title="添加错识表述词库" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
    <div class="niubi_box" v-loading="loading" element-loading-text="拼命保存中" element-loading-spinner="el-icon-loading">
      <div class="textTitle">添加词库</div>
      <el-alert
      :closable="false"
    title="提示：可以多个错误词对应一个正确词，也可义一个错误词对应一个正确词,当有多个错误词时，中间用空格隔开"
    type="warning"
    description="例如: 错误词1 错误词2 错误词3"
    show-icon>
  </el-alert>
      <div class="textTitle" style="margin-top:32px"><span style="color:red">*</span> 关键字词</div>
      <div class="inputBox">
        <el-input  v-model="cwc" placeholder="错误词,必填项"  clearable></el-input>
        <el-input style="margin-left:20px" v-model="zqc" placeholder="正确词"  clearable></el-input>
        <el-button style="margin-left:20px" type="primary" @click="addFn">添  加</el-button>
      </div>
      <div class="inputBox_detele" v-for="(item,inx) in keywordlist" :key="inx">
        <div>
          <span> 错误词：</span>
          <el-input disabled style="width:186px" v-model="item.inaccuracyWord" placeholder="错误词,必填项"  clearable></el-input>
        </div>
        <div>
          <span>正确词：</span>
          <el-input disabled style="width:186px" v-model="item.correctWord" placeholder="正确词"  clearable></el-input>
        </div>
        <el-link type="danger" :underline="false" @click="deleteFn(inx)">删 除</el-link>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="savePlanMisre">确 定</el-button>
    </span>
  </el-dialog>
  <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="dialogVisible2" width="500px">
    <div class="twoBox">
      <img style="width:180px;height:180px;text-align:center" src="@/assets/img/zhang/dui.png" alt="" srcset="">
      <p style="text-align:center;color:#F58030;font-size:20px">操作成功</p>
      <p style="text-align:center;margin-top:20px">相关词库待人工审核后生效</p>
    </div>
    <span slot="footer" class="footer2">
      <el-button type="primary" @click="savePlanMisre2">确 定</el-button>
    </span>
  </el-dialog>
  </div>

</template>

<script>
import Title from '@/base/Title.vue'
import { orgSavePlanMisre } from "@/api/error/index";
import {save} from '@/api/Thesaurus' 
export default {
  components: {
    Title
  },
  data() {
    return {
      dialogVisible: false, 
      loading: false,
      cwc:'', // 错误词
      zqc:'' , // 正确词
      keywordlist:[] , // 关键词组
      dialogVisible2:false
    }
  },
  methods: {
    deleteFn(inx) {
      this.keywordlist.splice(inx,1)
    },
    addFn() {
      if(!this.cwc) return  this.$message.warning("错误词是必填项！")
  let cwc  = this.cwc.trim() 
  let zqc  = this.zqc.trim() 
  this.keywordlist = [...this.keywordlist,{inaccuracyWord:cwc,correctWord:zqc}]
  this.cwc = ""
  this.zqc = ""
    },
    //新增错误表述
    async savePlanMisre() {
      if(this.keywordlist.length == 0) return this.$message.warning("请填写关键词后提交表单，或点击取消按钮返回")
      let Res= await save(this.keywordlist || [])
      if(Res.data.code == 200) {
      this.dialogVisible2 = true
      }
      this.dialogVisible = false;
      this.handleClose() 
    },
    savePlanMisre2() {
      this.dialogVisible2 = false
    },
    // 首先保证data里的纯度  然后暴力重置 
    handleClose() {
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>

<style scoped>
/* .el-input{ 
  width:80% !important;
} */
</style>
<style lang="scss" scoped>
.twoBox{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.footer2{
  display: flex;justify-content: center;
}
.textTitle {
  font-weight: 600;
  margin-bottom: 16px;
}
.niubi_box{
  padding:0 20px;
}
.inputBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.inputBox_detele{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #F5F6FA;
  padding: 16px;
  margin-top: 16px;
}
// .add-spe-act-one {
//   width: 100%;
//   background: #ffffff;
//   border-radius: 4px;
//   padding: 26px 20px;
//   .spe-act-one-form {
//     margin-left: 28px;
//     display: flex;
//     flex-direction: column;
//     .one-form-box {
//       display: flex;
//       margin-top: 32px;
//       .box-left {
//         min-width: 68px;
//         font-size: 14px;
//         font-family: PingFangSC-Medium, PingFang SC;
//         font-weight: 600;
//         color: #333333;
//         line-height: 36px;
//         margin-right: 14px;
//         span {
//           display: inline-block;
//           // height: 36px;
//           margin-right: 5px;
//           color: red;
//         }
//       }
//       .box-right {
//         display: flex;
//         .el-input {
//           width: 640px;
//         }
//         .el-checkbox {
//           margin: 8px 40px 0 0;
//         }
//         .el-select {
//           width: 380px;
//         }
//         .el-button {
//           min-width: 64px;
//           height: 36px;
//           margin-left: 16px;
//           background: #4cc8ff !important;
//           border: 1px solid #4cc8ff;
//         }
//         .el-date-editor {
//           width: 380px !important;
//         }
//       }
//     }
//   }
// }
// .box2 {
//   width: 100%;
//   background: #ffffff;
//   border-radius: 4px;
//   padding: 24px 20px;
//   margin-bottom: 16px;
//   .box2-conent {
//     padding-left: 28px;
//     .box2-conent-li {
//       display: flex;
//       height: 36px;
//       line-height: 36px;
//       margin-top: 24px;
//       .key-words-1 {
//         font-size: 14px;
//         font-family: PingFangSC-Medium, PingFang SC;
//         font-weight: 600;
//         color: #333333;
//         line-height: 36px;
//         margin-right: 18px;
//         span {
//           display: inline-block;
//           height: 36px;
//           margin-right: 5px;
//           color: red;
//         }
//       }
//       .key-words-2 {
//       }
//       .key-words-3 {
//       }
//       .key-words-4 {
//       }
//       .key-words-5 {
//       }
//       .key-words-6 {
//         margin-left: 16px;
//         color: #267dff;
//         font-size: 16px;
//         font-family: PingFangSC-Medium, PingFang SC;
//         font-weight: 500;
//         cursor: pointer;
//       }
//       div {
//         .el-input {
//           width: 150px;
//           margin-right: 14px;
//         }
//       }
//     }
//     .li3 {
//       position: relative;
//       width: calc(100% - 28px);
//       height: 204px;
//       background: #f5f6fa;
//       .li3-left {
//         width: 150px;
//         height: 36px;
//         margin: 84px 14px 84px 100px;
//       }
//       .li3-right {
//         display: flex;
//         flex-direction: column;
//         .el-input {
//           width: 572px;
//           margin-top: 24px;
//         }
//       }
//       .li3-text {
//         position: absolute;
//         left: 860px;
//         top: 41%;
//         font-size: 14px;
//         font-family: PingFangSC-Medium, PingFang SC;
//         font-weight: 500;
//         color: #e60b1e;
//         cursor: pointer;
//       }
//     }
//     .li4 {
//       .li4-left {
//         font-size: 14px;
//         font-family: PingFangSC-Medium, PingFang SC;
//         font-weight: 600;
//         color: #333333;
//         line-height: 36px;
//         margin-right: 16px;
//       }
//       .li4-right {
//         .el-input {
//           width: 749px;
//         }
//       }
//     }
//   }
// }
</style>